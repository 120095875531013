import { Link } from '@remix-run/react'
import React from 'react'
import H1 from '~/components/typography/H1'
import IconCaretRight from '~/static/svg/IconCaretRight.svg'

interface Props {
  text: string
  link: string
  className?: string
}

const H1WithArrowLink: React.FC<Props> = ({ className, text, link }) => {
  return (
    <Link
      to={link}
      prefetch="intent"
      className={className}
    >
      <div className={'flex items-center justify-end'}>
        <H1
          as={'p'}
          className="text-left"
        >
          {text}
        </H1>
        <img
          className="ml-6 mt-1"
          src={IconCaretRight}
          alt={text}
        />
      </div>
    </Link>
  )
}

export default H1WithArrowLink
